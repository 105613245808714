.fixed-adr {
	@include media('<sm') {
		display: none;
	}

	font-size: 16px;
	line-height: 1.25;

	&__link {
		text-decoration: none;

		color: white;

		body:not(.mobile) &:hover {
			text-decoration: underline;
		}
	}
}
