.reason {
	color: white;
	border-bottom: 4px solid $light-orange;
	background: $green url(../images/reason.jpg) no-repeat 50% 50% / cover;

	&__inner {
		@include media('<md') {
			display: block;

			padding: 40px 10px;
		}

		display: flex;

		max-width: 1220px;
		margin: 0 auto;
		padding: 0 20px;

		align-items: center;
		justify-content: space-between;
	}

	&__info {
		@include media('<md') {
			width: auto;
			max-width: none;
			padding: 0;
		}

		width: calc(100% - 448px);
		max-width: 640px;
		padding: 40px 20px 40px 0;
	}

	&__video {
		@include media('<md') {
			width: auto;
			max-width: 640px;
			margin-top: 20px;
			padding: 0;
		}

		width: 448px;
		padding: 20px 0;
	}
}
