.popup {
	position: fixed;
	z-index: 101;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	display: none;
	visibility: hidden;
	overflow-y: auto;

	transition: opacity $t, visibility $t;

	opacity: 0;

	&::before {
		display: inline-block;

		height: 100%;

		content: '';
		vertical-align: middle;
	}

	&__inner {
		@include media('<sm') {
			padding: 10px;
		}

		display: inline-block;

		width: 100%;
		padding: 20px;

		vertical-align: middle;
	}

	&__body {
		@include media('<sm') {
			padding: 18px;
		}

		position: relative;

		max-width: 540px;
		margin: 0 auto;
		padding: 28px;

		border: 2px solid $green;
		background: white;
	}

	&__close {
		@include media('<sm') {
			top: 13px;
			right: 13px;
		}

		position: absolute;
		top: 23px;
		right: 23px;

		display: block;

		width: 25px;
		height: 25px;
		padding: 0;

		cursor: pointer;

		border: 0;
		outline: none;
		background: none;

		&::before,
		&::after {
			position: absolute;
			top: calc(50% - 1px);
			left: 5px;

			display: block;

			width: 15px;
			height: 2px;

			content: '';
			transition: background $t;

			background: $green;
		}

		&::before {
			transform: rotate(45deg);
		}

		&::after {
			transform: rotate(-45deg);
		}

		body:not(.mobile) &:hover {
			&::before,
			&::after {
				background: $orange;
			}
		}
	}

	&__title {
		@include media('<sm') {
			font-size: 24px;
		}

		font-size: 36px;
		line-height: 1.25;

		margin-bottom: 10px;
	}

	&._active {
		display: block;
	}

	&._visible {
		visibility: visible;

		opacity: 1;
	}
}
