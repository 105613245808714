.social {
	@include reset-list;
	@include media('<md') {
		margin-top: 20px;
	}

	display: flex;

	margin: 0 -10px;

	align-items: center;

	&__item {
		margin: 0 10px;
	}

	&__link {
		display: block;

		transition: opacity $t;
		text-decoration: none;

		color: white;

		body:not(.mobile) &:hover {
			opacity: .75;
		}
	}

	&__icon {
		display: block;

		fill: currentColor;
	}
}
