.director {
	@include media('<sm') {
		padding: 0 10px;
	}
	@include media('<xs') {
		display: block;
	}

	display: flex;

	max-width: 940px;
	margin: 40px auto;
	padding: 0 20px;

	align-items: center;
	flex-wrap: wrap;

	&__hero {
		@include media('<sm') {
			width: 150px;
		}
		@include media('<xs') {
			margin: 0 auto;
		}

		width: 200px;

		align-self: flex-start;
	}

	&__image {
		display: block;

		max-width: 100%;
		height: auto;

		border-radius: 50%;
	}

	&__body {
		@include media('<sm') {
			width: calc(100% - 150px);
		}
		@include media('<xs') {
			width: auto;
			margin-top: 26px;
			padding-left: 0;
		}

		width: calc(100% - 200px);
		padding-left: 25px;
	}

	&__cloud {
		position: relative;

		padding: 15px;

		border: 1px solid #e6e6e6;
		border-radius: 2px;
		background: #fdfaf8;

		&::before {
			@include media('>=xs') {
				@include triangle(20px 18px, #e6e6e6, up-right);

				top: 16px;
				right: 100%;
			}
			@include media('<xs') {
				@include triangle(30px 16px, #e6e6e6, up);

				bottom: 100%;
				left: calc(50% - 15px);
			}

			position: absolute;

			content: '';
		}

		&::after {
			@include media('>=xs') {
				@include triangle(20px 18px, #fdfaf8, up-right);

				top: 17px;
				right: calc(100% - 2px);
			}
			@include media('<xs') {
				@include triangle(30px 16px, #fdfaf8, up);

				bottom: calc(100% - 2px);
				left: calc(50% - 15px);
			}

			position: absolute;

			content: '';
		}

		p {
			@include margin-bottom(.3334em);

			margin: 0;
		}
	}

	&__signature {
		font-weight: bold;

		margin: 20px 0 0 16px;
	}
}
