.overlay {
	position: fixed;
	z-index: 99;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	visibility: hidden;

	transition: all $t;

	opacity: 0;
	background: rgba(#0149a5, .16);

	&._visible {
		visibility: visible;

		opacity: 1;
	}
}
