.quotes {
	position: relative;

	max-width: 1220px;
	margin: 40px auto;

	&__item {
		@include media('<sm') {
			padding: 0 10px;
		}

		height: auto;
		padding: 0 20px;

		align-self: center;
	}

	&__arrow {
		position: absolute;
		z-index: 1;
		top: calc(50% - 14px);

		padding: 0;

		cursor: pointer;

		color: white;
		border: 0;
		outline: none;
		background: none;

		&._type_prev {
			@include media('<sm') {
				left: 10px;
			}

			left: 20px;
		}

		&._type_next {
			@include media('<sm') {
				right: 10px;
			}

			right: 20px;
		}
	}

	&__arrow-icon {
		display: block;

		width: 19px;
		height: 28px;

		transition: stroke $t;

		fill: currentColor;
		stroke: #cae9ff;
		stroke-width: 1px;

		body:not(.mobile) .quotes__arrow:hover & {
			stroke: $orange;
		}
	}
}
