.text {
	p {
		@include margin-bottom(1.3334em);

		margin: 0;
	}

	ul {
		@include reset-list;
		@include margin-bottom(1.3334em);

		& > li {
			@include margin-bottom(.3334em);

			padding-left: 20px;

			&::before {
				display: block;
				float: left;

				width: 9px;
				height: 3px;
				margin: 10px 0 0 -20px;

				content: '';

				background: $green;
			}
		}
	}

	table {
		@include media('<sm') {
			font-size: 16px;
		}
		@include media('<xs') {
			font-size: 14px;
		}

		width: 100%;
		max-width: 900px;
		margin: 2em auto;

		border-collapse: collapse;

		tr:nth-child(2n+1) td {
			background: #fdfaf8;
		}

		td {
			@include media('<sm') {
				padding: 10px;
			}

			padding: 20px;

			border: 1px solid #e6e6e6;

			&:nth-child(1) {
				@include media('<xs') {
					width: auto;
				}

				width: 60%;
			}

			&:nth-child(2) {
				@include media('<xs') {
					width: auto;
				}

				width: 40%;
			}
		}
	}
}
