.callorder-btn {
	@include media('<xs') {
		display: block;

		width: 100%;
	}

	line-height: 1.1875;

	display: inline-block;

	padding: 8px 15px;

	cursor: pointer;
	text-align: center;
	text-decoration: none;

	color: white;
	border: 0;
	border-radius: 2px;
	outline: none;
	background: $orange;

	&__text {
		border-bottom: 1px dotted;
	}
}
