.footer-logo {
	@include media('<md') {
		max-width: 135px;
		margin-bottom: 20px;
	}

	max-width: 204px;
	margin-bottom: 30px;

	&__link {
		display: block;
	}

	&__image {
		display: block;

		max-width: 100%;
		height: auto;
	}
}
