.logo {
	@include media('<md') {
		display: none;
	}

	width: 204px;

	&__link {
		display: block;

		text-decoration: none;
	}

	&__image {
		display: block;

		max-width: 100%;
		height: auto;
	}
}
