.services {
	@include media('<sm') {
		padding: 0 10px;
	}

	max-width: 1100px;
	margin: 40px auto;
	padding: 0 20px;

	&__list {
		display: flex;

		margin-top: -30px;

		flex-wrap: wrap;
	}

	&__item {
		@include media('<md') {
			width: calc(50% - 20px);
		}
		@include media('<sm') {
			width: 100%;
		}

		width: calc(50% - 50px);
		margin-top: 30px;

		&:nth-child(2n+1) {
			@include media('<md') {
				margin-right: 20px;
			}
			@include media('<sm') {
				margin-right: 0;
			}

			margin-right: 50px;
		}

		&:nth-child(2n) {
			@include media('<md') {
				margin-left: 20px;
			}
			@include media('<sm') {
				margin-left: 0;
			}

			margin-left: 50px;
		}
	}
}
