.footer {
	color: white;
	border-top: 4px solid $light-orange;
	background: $grey;

	&__inner {
		@include media('<md') {
			display: block;

			padding: 40px 20px 20px;
		}
		@include media('<md') {
			padding: 20px 10px;
		}

		display: flex;

		max-width: 1220px;
		margin: 0 auto;
		padding: 40px 10px 20px;
	}

	&__leftside {
		@include media('<md') {
			width: auto;
			padding: 0;
		}

		width: 25%;
		padding: 0 10px;
	}

	&__rightside {
		@include media('<md') {
			display: block;

			width: auto;
			padding: 0;
		}

		display: flex;
		flex-direction: column;

		width: 75%;
		padding: 0 10px;

		justify-content: space-between;
	}

	&__nav {
		@include media('<md') {
			display: none;
		}

		display: flex;

		width: calc(100% + 20px);
		margin: 0 -10px;

		flex-wrap: wrap;
	}

	&__nav-column {
		width: calc(100% / 3);
		padding: 0 10px;
	}

	&__caption {
		font-size: 16px;
		line-height: 1.5;

		margin-bottom: .5em;

		text-transform: uppercase;

		color: $green;
	}

	&__copyright {
		@include media('<md') {
			margin-top: 20px;
		}

		font-size: 16px;
		line-height: 1.5;

		width: 100%;
		margin-top: 30px;
	}
}
