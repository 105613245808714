.fixed-logo {
	width: 135px;

	&__link {
		display: block;
	}

	&__image {
		display: block;

		max-width: 100%;
		height: auto;
	}
}
