.header-tel {
	font-size: 24px;
	line-height: 1;

	letter-spacing: -.025em;

	&__link {
		text-decoration: none;

		color: $grey;
	}

	&__number {
		font-size: 30px;

		color: $orange;
	}
}
