.header {
	@include media('>=md') {
		z-index: 10;
	}
	@include media('<md') {
		height: 37px;
	}

	position: relative;

	&__inner {
		@include media('<md') {
			position: fixed;
			z-index: 100;
			top: 0;
			left: 0;

			display: block;
			overflow-y: auto;

			width: 100%;
			max-width: 420px;
			max-height: 100%;

			transition: transform $t, opacity $t;
			transform: translateX(-100%);

			opacity: 0;
			background: white;
		}
		@include media('<sm') {
			max-width: 400px;
		}

		display: flex;
		flex-direction: column;

		align-items: center;

		.header._open & {
			@include media('<md') {
				transform: translateX(0);

				opacity: 1;
			}
		}

		&::before {
			@include media('<md') {
				display: block;

				height: 37px;

				content: '';

				border-bottom: 4px solid $green;
				background: $grey;
			}
		}
	}

	&__top {
		@include media('<md') {
			border-top: 4px solid $green;
			border-bottom: 0;
		}

		width: 100%;

		border-bottom: 4px solid $green;
		background: $grey;
	}

	&__top-inner {
		@include media('<md') {
			display: block;

			padding: 10px 20px 20px;
		}
		@include media('<sm') {
			padding: 10px 10px 20px;
		}

		display: flex;

		max-width: 1220px;
		margin: 0 auto;
		padding: 6px 20px;

		align-items: center;
		justify-content: space-between;
	}

	&__base {
		@include media('<md') {
			padding: 20px;
		}
		@include media('<sm') {
			padding: 20px 10px;
		}

		display: flex;

		width: 100%;
		max-width: 1220px;
		margin: 0 auto;
		padding: 50px 20px;

		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;
		order: 1;
	}

	&__contacts {
		@include media('<md') {
			width: 100%;

			justify-content: space-between;
		}
		@include media('<xs') {
			display: block;
		}

		display: flex;

		align-items: center;
	}

	&__contacts-left {
		@include media('<lg') {
			margin-right: 40px;
		}
		@include media('<md') {
			margin-right: 0;
		}
		@include media('<xs') {
			text-align: left;
		}

		margin-right: 60px;

		text-align: right;
	}

	&__contacts-right {
		@include media('<xs') {
			margin-top: 20px;
		}
	}

	&__close-btn {
		@include media('>=md') {
			display: none;
		}
		@include media('<sm') {
			left: 10px;
		}

		position: absolute;
		top: 4px;
		left: 20px;

		width: 25px;
		height: 25px;
		padding: 0;

		cursor: pointer;

		border: 0;
		outline: none;
		background: none;

		&::before,
		&::after {
			position: absolute;
			top: 11px;
			left: 0;

			display: block;

			width: 25px;
			height: 3px;

			content: '';

			background: white;
		}

		&::before {
			transform: rotate(45deg);
		}

		&::after {
			transform: rotate(-45deg);
		}
	}
}
