.btn {
	font-size: 18px;
	line-height: 1.3334;

	display: inline-block;

	padding: 5px 15px 4px;

	cursor: pointer;

	color: white;
	border: 0;
	border-radius: 2px;
	outline: none;
	background: $orange;
}
