.agreement {
	font-size: 14px;
	line-height: 1.7143;

	position: relative;

	margin-bottom: 20px;

	&__checkbox {
		position: absolute;

		visibility: hidden;
	}

	&__label {
		position: relative;

		display: inline-block;

		padding-left: 26px;

		cursor: pointer;

		color: inherit;

		&::before {
			position: absolute;
			top: 3px;
			left: 0;

			display: block;

			width: 16px;
			height: 16px;

			content: '';

			border: 1px solid;
			border-radius: 2px;
		}

		&::after {
			position: absolute;
			top: 2px;
			left: 3px;

			display: block;

			width: 14px;
			height: 14px;

			content: '';

			opacity: 0;
			background: url(../images/tick.svg) no-repeat 50% 50%;
		}

		.agreement__checkbox:checked + &::after {
			opacity: 1;
		}
	}

	&__link {
		color: white;
	}
}
