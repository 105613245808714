.feedback {
	margin: 40px 0;

	color: white;
	border-bottom: 4px solid $light-orange;
	background: $grey;

	&__inner {
		@include media('<sm') {
			display: block;

			padding: 40px 10px 36px;
		}

		display: flex;

		max-width: 1220px;
		margin: 0 auto;
		padding: 50px 20px 46px;
	}

	&__info {
		@include media('<md') {
			padding-right: 20px;
		}
		@include media('<sm') {
			width: auto;
			padding-right: 0;
		}

		width: 50%;
		padding-right: 50px;
	}

	&__form {
		@include media('<md') {
			max-width: 405px;
			padding-left: 20px;
		}
		@include media('<sm') {
			width: auto;
			max-width: 385px;
			margin-top: 20px;
			padding-left: 0;
		}

		width: 50%;
		max-width: 435px;
		padding-left: 50px;
	}
}
