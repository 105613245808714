.video {
	position: relative;

	&::before {
		position: relative;

		display: block;

		width: 100%;
		padding-top: 56.25%;

		content: '';
	}

	&__player {
		position: absolute;
		top: 0;
		left: 0;

		display: block;

		width: 100%;
		height: 100%;
	}

	&__btn {
		position: absolute;
		top: 0;
		left: 0;

		display: block;

		width: 100%;
		height: 100%;
		padding: 0;

		cursor: pointer;

		border: 0;
		outline: none;
		background: none;

		.video._active & {
			display: none;
		}
	}
}
