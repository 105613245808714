.fixed-header {
	@include media('<md') {
		z-index: 10;

		transform: translateY(0);

		opacity: 1;
	}

	position: fixed;
	top: 0;
	left: 0;

	width: 100%;

	transition: transform $t, opacity $t;
	transform: translateY(-100%);

	opacity: 0;
	color: white;
	border-bottom: 4px solid $green;
	background: $grey;

	&._visible {
		transform: translateY(0);

		opacity: 1;
	}

	&__inner {
		@include media('<md') {
			padding-left: 65px;
		}
		@include media('<sm') {
			padding: 5px 10px 5px 50px;
		}

		display: flex;

		max-width: 1220px;
		margin: 0 auto;
		padding: 5px 20px;

		align-items: center;
		justify-content: space-between;
	}

	&__toggle {
		@include media('>=md') {
			display: none;
		}
		@include media('<sm') {
			left: 10px;
		}

		position: absolute;
		top: calc(50% - 13px);
		left: 20px;

		width: 25px;
		height: 25px;
		padding: 0;

		cursor: pointer;

		border: 0;
		outline: none;
		background: none;

		&::before,
		&::after,
		& > i {
			position: absolute;
			left: 0;

			display: block;

			width: 25px;
			height: 3px;

			content: '';

			background: white;
		}

		&::before {
			top: 3px;
		}

		& > i {
			top: 11px;
		}

		&::after {
			top: 19px;
		}
	}
}
