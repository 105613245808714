.footer-tel {
	@include media('<md') {
		margin-bottom: 20px;
	}

	margin-bottom: 30px;

	&__link {
		font-size: 24px;
		line-height: 1;

		text-decoration: none;

		color: white;
	}

	&__number {
		font-size: 30px;

		color: $green;
	}
}
