.service {
	&__caption {
		font-weight: bold;

		display: inline;

		margin-right: 15px;
	}

	&__link {
		color: #bf5300;
	}

	&__price {
		font-weight: bold;

		display: inline;
	}

	&__text {
		font-size: 16px;
		line-height: 1.25;

		margin-top: 10px;
	}
}
