.fixed-nav {
	@include media('<md') {
		display: none;
	}

	font-size: 16px;
	line-height: 1.25;

	&__list {
		@include reset-list;

		display: flex;

		flex-wrap: wrap;
	}

	&__item {
		&:nth-last-child(n+2) {
			margin-right: 30px;
		}
	}

	&__link {
		color: white;
	}
}
