.clients {
	@include media('<sm') {
		padding: 0 10px;
	}

	max-width: 1220px;
	margin: 40px auto;
	padding: 0 20px;

	&__title {
		text-align: center;
	}

	&__slider {
		margin: 40px -10px 0;
	}

	&__item {
		@include media('<md') {
			width: 25%;
		}
		@include media('<sm') {
			width: calc(100% / 3);
		}
		@include media('<xs') {
			width: 50%;
		}

		display: flex;

		width: 20%;
		height: auto;
		padding: 0 10px;

		align-items: center;
		justify-content: center;
	}

	&__image {
		display: block;

		max-width: 100%;
		height: auto;
		margin: 0 auto;
	}
}
