.promo {
	&__item {
		@include media('<sm') {
			width: 268px;
			height: 150px;
		}

		width: 415px;
		height: 233px;

		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: cover;
	}

	&__image {
		display: block;
	}
}
