.secondary-nav {
	&__list {
		@include reset-list;
		@include media('<md') {
			display: block;

			margin: 0;
		}

		font-size: 16px;
		line-height: 1.25;

		display: flex;

		margin: 0 -15px;
	}

	&__item {
		@include media('<md') {
			margin: 0;

			border-bottom: 1px solid rgba(white, .25);
		}

		margin: 0 15px;
	}

	&__link {
		@include media('<md') {
			display: block;

			padding: 10px 0;

			text-decoration: none;
		}

		color: white;
	}
}
