.fixed-tel {
	font-size: 16px;
	line-height: 1.25;

	&__link {
		text-decoration: none;

		color: white;
	}
}
