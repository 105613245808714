.confidence {
	background: #f8f8f8;

	&__inner {
		@include media('<sm') {
			padding: 40px 10px;
		}

		max-width: 1220px;
		margin: 0 auto;
		padding: 40px 20px;
	}

	&__title {
		text-align: center;
	}
}
