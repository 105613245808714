.quote {
	@include media('<lg') {
		padding-left: 100px;
	}
	@include media('<md') {
		padding: 0 30px;

		text-align: center;
	}
	@include media('<sm') {
		font-size: 24px;
	}
	@include media('<xs') {
		font-size: 20px;
	}

	font-size: 30px;
	line-height: 1.25;

	padding: 0 30px 0 200px;

	color: #8b8b8b;

	&__line-1 {
		display: block;
	}

	&__line-2 {
		@include media('<md') {
			margin-left: 0;
		}

		display: block;

		margin-left: 40px;
	}

	&__line-3 {
		@include media('<md') {
			margin-left: 0;
		}

		display: block;

		margin-left: 80px;
	}

	&__highlight {
		color: $orange;
	}
}
