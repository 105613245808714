.header-adr {
	@include media('<md') {
		display: none;
	}
	@include media('<sm') {
		display: block;

		width: 100%;
		margin-top: 20px;
	}

	font-size: 16px;
	line-height: 1.25;

	position: relative;

	padding-left: 26px;

	color: $grey;

	&::before {
		position: absolute;
		top: 1px;
		left: 0;

		display: block;

		width: 16px;
		height: 16px;

		content: '';
		vertical-align: top;

		background: url(../images/map.svg) no-repeat 50% 50%;
	}

	&__link {
		text-decoration: none;

		color: $grey;

		body:not(.mobile) &:hover {
			text-decoration: underline;
		}
	}
}
