.footer-list {
	@include reset-list;
	@include media('<md') {
		display: flex;

		margin: 0 -10px;

		flex-wrap: wrap;
	}

	font-size: 16px;
	line-height: 1.5;

	&__item {
		@include media('<md') {
			margin: 0 10px;
		}
	}

	&__link {
		color: white;
	}
}
