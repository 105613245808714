.form {
	&__group {
		@include margin-bottom(16px);
	}

	&__entry {
		font-size: 18px;
		line-height: 1.3334;

		display: block;

		width: 100%;
		height: 35px;
		padding: 5px 14px 4px;

		color: $dark-grey;
		border: 1px solid #999;
		border-radius: 2px;
		outline: none;
		background: white;

		&._textarea {
			height: 75px;

			resize: none;
		}

		&::placeholder {
			opacity: 1;
			color: #949494;
		}
	}
}
