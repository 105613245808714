.nav {
	@include media('<md') {
		border-bottom: 0;
	}

	width: 100%;

	border-top: 1px solid #e8e8e8;
	border-bottom: 1px solid #e8e8e8;
	background: #fffbf8;

	order: 1;

	&__list {
		@include reset-list;
		@include media('<md') {
			display: block;
		}

		display: flex;

		max-width: 1220px;
		margin: 0 auto;
	}

	&__item {
		@include media('<md') {
			overflow: hidden;
		}

		position: relative;

		transition: background $t;

		&:nth-last-child(n+2) {
			@include media('>=lg') {
				margin-right: 10px;
			}
			@include media('<md') {
				border-bottom: 1px solid #e8e8e8;
			}
		}

		body:not(.mobile) &:hover {
			@include media('>=md') {
				background: $orange;
			}
		}
	}

	&__item-toggle {
		@include media('<md') {
			top: 0;
			right: 0;

			width: 50px;
			height: 40px;
		}
		@include media('<sm') {
			width: 30px;
		}

		position: absolute;
		top: 22px;
		right: 13px;

		display: block;

		padding: 0;

		cursor: pointer;
		transition: color $t;

		color: $orange;
		border: 0;
		outline: none;
		background: none;

		.nav__item._open & {
			@include media('>=md') {
				color: white;
			}
		}
	}

	&__item-icon {
		@include media('<md') {
			position: absolute;
			top: calc(50% - 4px);
			left: calc(50% - 5px);

			width: 10px;
			height: 8px;
		}

		display: block;

		width: 7px;
		height: 5px;

		transition: transform $t;

		fill: currentColor;

		.nav__item._open & {
			transform: rotate(180deg);
		}
	}

	&__link {
		@include media('<md') {
			position: static;

			padding: 10px 20px;

			text-decoration: none;
		}
		@include media('<sm') {
			padding: 10px;
		}

		font-size: 16px;
		line-height: 1.25;

		position: relative;

		display: block;

		padding: 15px 20px;

		transition: color  $t;
		text-transform: uppercase;

		color: $grey;

		.nav__item-toggle + & {
			@include media('<md') {
				padding-right: 50px;
			}
			@include media('<sm') {
				padding-right: 30px;
			}

			padding-right: 25px;
		}

		body:not(.mobile) &:hover,
		.nav__item._open & {
			@include media('>=md') {
				text-decoration: underline;

				color: white;
			}
		}
	}

	&__submenu {
		@include reset-list;
		@include media('<md') {
			left: 0;

			overflow: hidden;

			width: 100%;
			padding: 0 20px;

			transition: height $t;

			border: 0;
		}

		font-size: 16px;
		line-height: 1.25;

		position: absolute;
		top: 100%;
		left: -1px;

		visibility: hidden;

		min-width: calc(100% + 2px);
		padding: 15px 20px;

		transition: opacity $t, visibility $t;

		opacity: 0;
		border: 1px solid #e8e8e8;
		background: white;

		.nav__item._open & {
			@include media('<md') {
				position: static;
			}

			visibility: visible;

			opacity: 1;
		}
	}

	&__submenu-item {
		@include margin-bottom(10px);
		@include media('<md') {
			white-space: normal;
		}

		white-space: nowrap;

		&:first-child {
			@include media('<md') {
				padding-top: 10px;
			}
		}

		&:last-child {
			@include media('<md') {
				padding-bottom: 10px;
			}
		}
	}

	&__submenu-link {
		transition: color $t;
		text-decoration: none;

		color: $grey;

		body:not(.mobile) &:hover {
			color: $green;
		}
	}
}
