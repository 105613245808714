// Fonts
$ptsansnarrow: 'PT Sans Narrow', sans-serif;

// Colors
$dark-grey: #3c3c3c;
$orange: #ff6f00;
$green: #36d792;
$grey: #585858;
$light-orange: #ffb273;

// Transition
$t: .2s ease-in-out;

// Breakpoints
$breakpoints: (
	'xs': 568px,
	'sm': 768px,
	'md': 1025px,
	'lg': 1220px
);
